import { Injectable, NgZone } from '@angular/core';
import { environment as env } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { UserRoleModel } from '../models';
import { Router } from '@angular/router';

// Firebase
import {
  Auth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendEmailVerification,
  User,
  UserCredential,
  confirmPasswordReset,
  verifyPasswordResetCode,
  applyActionCode,
} from '@angular/fire/auth';
import { AuthRepository } from './auth.repository';
import { IntercomService } from './intercom.service';

/**
 * Service responsible for handling authentication-related functionality.
 */
@Injectable()
export class AuthService {
  private userSession: string = '';
  userData!: User;

  constructor(
    private auth: Auth,
    private _router: Router,
    public ngZone: NgZone,
    private _http: HttpClient,
    private authRepository: AuthRepository,
    private _intercomService: IntercomService,
  ) {
    onAuthStateChanged(this.auth, (user: any) => {
      if (user) {
        this.userData = user;
        this._intercomService.initialize(user);
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });
  }

  getIdToken(): Observable<string | null> {
    return new Observable((observer) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          user
            .getIdToken()
            .then((idToken) => {
              observer.next(idToken);
              observer.complete();
            })
            .catch((error) => {
              observer.error(error);
              observer.complete();
            });
        } else {
          observer.next(null);
          observer.complete();
        }
      });
    });
  }

  //get User
  //get Authenticated user from firebase
  getAuthFire() {
    return this.auth.currentUser;
  }

  //get Authenticated user from Local Storage
  getAuthLocal() {
    const token = localStorage.getItem('user');
    const user = JSON.parse(token as string);
    return user;
  }

  //Check wither User Is looged in or not
  get isLoggedIn(): boolean {
    const token = localStorage.getItem('user');
    const user = JSON.parse(token as string);
    return user !== null ? true : false;
  }

  //Register Method
  register(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password)
      .then((result) => {
        this.userData = result.user;
        this.ngZone.run(() => {
          /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
          this.sendEmailVerification();
          this._router.navigate(['/']);
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  //Login Method
  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password)
      .then((result: UserCredential) => {
        this.userData = result.user;
        this.ngZone.run(() => {
          this._router.navigate(['/']);
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  confirmPasswordReset(code: string, newPassword: string) {
    return confirmPasswordReset(this.auth, code, newPassword);
  }

  verifyPasswordResetCode(code: string) {
    return verifyPasswordResetCode(this.auth, code);
  }

  verifyEmailAddress(code: string) {
    return applyActionCode(this.auth, code);
  }

  //Logout
  logout() {
    signOut(this.auth).then(() => {
      this.userSession = '';
      localStorage.setItem('user', 'null');
      window.location.href = '/account/sign-in'; // Fuerza recarga completa
    });
  }

  //login with Email or Facebook
  //Login with Google
  GoogleAuth() {
    return this.loginWithPopup(new GoogleAuthProvider());
  }

  //Login with Facebook
  //FacebookAuth() {
  //  return this.loginWithPopup(new FacebookAuthProvider());
  //}

  //Pop Up Provider
  loginWithPopup(provider: any) {
    return signInWithPopup(this.auth, provider).then(() => {
      this._router.navigate(['/']);
    });
  }

  //Send Password Reset Email
  async sendPasswordResetEmails(email: string) {
    sendPasswordResetEmail(this.auth, email).catch((error) => {
      throw error;
    });
  }

  //Send Email Verification
  sendEmailVerification() {
    return sendEmailVerification(this.auth.currentUser as User);
  }

  /**
   * Retrieves the current user session token.
   */
  get token() {
    return this.userSession;
  }

  /**
   * Initializes the service with the provided token.
   * @param token - The session token to be set.
   */
  initializeService(token: string): void {
    this.userSession = token;
  }

  /**
   * Retrieves the user session from the server.
   * @returns An observable that emits the user session.
   */
  getSession(): Observable<any> {
    return this._http.post<any>(`${env.api.serverUrl}/auth/session`, {});
  }

  /**
   * Retrieves the user roles from the server.
   * @returns An observable that emits the user roles.
   */
  loadRoles(roleForResourceType: string = ''): Observable<UserRoleModel[]> {
    return this._http.get<UserRoleModel[]>(`${env.api.serverUrl}/auth/roles?resourceType=${roleForResourceType}`).pipe(
      tap((roles) => {
        this.authRepository.setRoles(roles, roleForResourceType);
      }),
    );
  }
}
